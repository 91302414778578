<template>
	<div>
		<div class="js-copy js-content">
			<div class="ac_pc">
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/web_hk_ac1.jpg?v=4"
					alt=""
				/>
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/web_hk_ac2.jpg?v=4"
					alt=""
				/>
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/web_hk_ac3.jpg?v=4"
					alt=""
				/><img
					style="width: 100%"
					src="https://zy.metaera.media/activity/web_hk_ac4.jpg?v=4"
					alt=""
				/>
			</div>
			<div class="ac_phone">
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/app_hk_ac1.jpg?v=4"
					alt="" />
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/app_hk_ac2.jpg?v=4"
					alt="" />
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/app_hk_ac3.jpg?v=4"
					alt="" />
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/app_hk_ac4.jpg?v=4"
					alt="" />
				<img
					style="width: 100%"
					src="https://zy.metaera.media/activity/app_hk_ac5.jpg?v=4"
					alt=""
			/></div>
		</div>
		<div class="ac_goAction pointer" @click="goAction"></div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return { osafai: false, olead: false };
	},
	created() {},
	methods: {
		goAction() {
			window.open("https://tally.so/r/mY44Oz", "");
		},
	},
};
</script>

<style scoped>
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	/* background: #000; */
	margin: 0 auto;
}

.ac_goAction {
	/* background: red; */
	position: absolute;
	margin-top: 80px;
	top: calc(100vw * 0.32);
	width: calc(100vw * 0.2);
	left: calc(100vw * 0.4);
	height: calc(100vw * 0.08);
	text-align: center;
}

.ac_pc {
	display: block;
}
.ac_phone {
	display: none;
}

@media screen and (max-width: 950px) {
	.ac_pc {
		display: none;
	}
	.ac_phone {
		display: block;
	}
	.js-copy {
		position: absolute;
		top: 0px;
		left: 0;
		width: calc(100vw);
		/* background: #000; */
		margin: 0 auto;
	}

	.ac_goAction {
		/* background: red; */
		position: absolute;
		margin-top: 60px;
		top: calc(100vw * 0.7);
		width: calc(100vw * 0.5);
		left: calc(100vw * 0.25);
		height: calc(100vw * 0.2);
		text-align: center;
	}
}
</style>
